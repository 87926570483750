<template>
  <div :style="getElementStyle" v-loading="loading">
    <el-row align="middle">
      <el-col :span="showLabel && !isDefalutPosq ? 4 : data.description ? 20 : 24" :style="computedStyles">
        <label for="text" style="margin-right: 5px; font-weight: bold">{{
          data.label
        }}</label>
        <span v-if="data.validations.required" style="color: red; font-weight: bold">
          *
        </span>
        <!-- <i class="el-icon-copy-document" v-if="!isDataTableField && !isView" @click="copyDocument"></i> -->
        <span>
          <el-popover v-if="data.description" placement="top-start" trigger="hover" :content="data.description">
            <i class="el-icon-info" slot="reference"></i>
          </el-popover>
        </span>
      </el-col>
      <el-col :span="showLabel && !isDefalutPosq ? 12 : 24">
        <div v-if="!isView">
          <template v-if="
            data.integration_settings.selectedIntegrationFieldVariableType ===
            'select'
          ">
            <el-select v-model="form[data.key]" placeholder="Select an option" :style="getStyle">
              <el-option v-for="(item, index) in integrationVariables" :key="index" :label="item.label"
                :value="item.value"></el-option>
            </el-select>
          </template>
          <template v-else-if="
            data.integration_settings.selectedIntegrationFieldVariableType ===
            'date'
          ">
            <el-date-picker v-model="form[data.key]" :placeholder="data.placeholder" :style="getStyle" type="date"
              format="dd-MM-yyyy" />
          </template>
          <template v-else-if="
            data.integration_settings.selectedIntegrationFieldVariableType ===
            'text'
          ">
            <el-input v-model="form[data.key]" :placeholder="data.placeholder" :suffix-icon="data.properties.el_icon"
              :style="getStyle" />
          </template>
          <span v-if="isActive || isDataTableField" class="setting-icon" @click="openSettings">
            <i class="el-icon-s-tools" />
          </span>
        </div>
        <div v-else>
          {{ form[this.data.key] }}
        </div>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import { postAPICall } from "../../../helpers/httpHelper";
export default {
  components: {},
  props: [
    "data",
    "value",
    "isActive",
    "isDataTableField",
    "isInDataTable",
    "form",
    "fromRepeatable",
    "fieldsData",
    "entityDataId",
    "entityId",
    "isHideField",
    "highlight",
    "fromEntityViews",
    "isView",
    "hasLabel",
    "integrationData",
  ],
  computed: {
    computedStyles() {
      const styles = {};
      styles.display = "flex";
      if (this.data?.styles?.labelAlignments) {
        styles.justifyContent = this.data.styles.labelAlignments;
      }
      return styles;
    },
    getStyle() {
      return `height:${this.data.height - 30}px;`;
    },
    getElementStyle() {
      let borderStyle = "";
      if (this.data.styles) {
        let type = this.data.styles.border_type
          ? this.data.styles.border_type
          : "solid";
        let size = this.data.styles.border_size
          ? this.data.styles.border_size + "px"
          : "0px";
        let color = this.data.styles.border_color
          ? this.data.styles.border_color
          : "";
        let font_size =
          this.data.styles &&
            this.data.styles.font &&
            this.data.styles.font.font_size
            ? this.data.styles.font.font_size
            : 14;
        let bold =
          this.data.styles &&
            this.data.styles.font &&
            (this.data.styles.font.style == 1 || this.data.styles.font.style == 3)
            ? "bold"
            : "";
        let italic =
          this.data.styles &&
            this.data.styles.font &&
            (this.data.styles.font.style == 2 || this.data.styles.font.style == 3)
            ? "italic"
            : "";

        borderStyle = "border:" + type + " " + size + " " + color;

        borderStyle += ";";
        borderStyle += this.data.styles.background
          ? `background: ${this.data.styles.background}`
          : "";
        borderStyle += ";";
        borderStyle += "font-size:" + font_size + "px;";
        if (bold) {
          borderStyle += "font-weight:" + bold + ";";
        }
        if (italic) {
          borderStyle += "font-style:" + italic + ";";
        }
        borderStyle += this.data.styles.label_color
          ? `color: ${this.data.styles.label_color}`
          : "";
        borderStyle += ";";
      }
      return borderStyle;
    },
    isDefalutPosq() {
      return this.data.styles && this.data.styles.labelStyle !== "right";
    },
  },
  data() {
    return {
      validations: [],
      options: [],
      showLabel: true,
      integrations: [],
      selectedValue: this.data.key,
      loading: false,
      integrationVariables: [],
      integrationVariablesObj : {}
    };
  },
  watch: {
    integrationData: {
      deep: true,
      immediate: true,
      handler(newVal, oldVal) {
        if (newVal !== oldVal) {
          this.handleFormChange(newVal);
        }
      },
    },
  },
  async mounted() {
    if (!this.isView) {
      await this.fetchIntegrations();
      await this.fetchAllPredefinedIntegrations();
      await this.getRequiredData();
    }
  },
  methods: {
    copyDocument() {
      this.$emit("copy-document", this.data.key);
    },
    openSettings() {
      this.$emit("settings");
    },
    async fetchEventData(requestBody) {
      try {
        const response = await postAPICall(
          "POST",
          `integrations/service/get-event-data`,
          requestBody
        );
        const data = response.data;
        return data;
      } catch (error) {
        return error;
      }
    },
    async fetchIntegrations() {
      try {
        let params = {
          limit: 10,
          page: 1,
        };
        let integrationData = await postAPICall("GET", `integrations`, params);
        this.integrations = integrationData.data;
        return this.integrations;
      } catch (err) {
        return err;
      }
    },
    async fetchAllPredefinedIntegrations() {
      const response = await postAPICall(
        "GET",
        "integrations/service/get-all-applications"
      );
      this.predefinedIntegrations = response?.data || [];
    },
    async handleFormChange() {
      try {
        let asset;
        let integrationField = this.fieldsData.find(
          (field) =>
            field.key == this.data.integration_settings.selectedIntegrationField
        );
        let connectionField = this.fieldsData.find(
          (field) => field.integration_settings.selectedIntegration
        );
        let applicationSelected =
          integrationField.integration_settings.selectedApplication;
        let application = this.predefinedIntegrations?.find(
          (selectedApp) => selectedApp._id === applicationSelected
        );
        let app = application.key;
        let eventsArray = application.events;
        let eventFromIntegration =
          this.data.integration_settings.selectedVariableEvent;
        let event = eventsArray.find(
          (selectedEvent) => String(selectedEvent._id) === eventFromIntegration
        );
        asset = event.asset;
        let requiredData = {};
        let requiredKeys = event.settings.requiredFields.map((field) => {
          return field.key;
        });
        let requiredEvents = eventsArray.filter((event) =>
          requiredKeys.includes(event.asset)
        );
        requiredEvents.map((event) => {
          this.fieldsData.map((field) => {
            if (event._id == field.integration_settings.selectedEvent) {
              this.$set(requiredData, event.asset, this.form[field.key]);
            }
          });
        });
        let requestBody = {
          selectedApplication: app,
          selectedEvent: asset,
          selectedIntegration:
            connectionField.integration_settings.selectedIntegration,
          requiredFields: requiredData,
        };
        const responseData = await this.fetchEventData(requestBody);
        // let linkedColumn =
        // this.data.integration_settings["selectedIntegrationFieldVariable"];
        // let value = this.data?.integration_settings?.selectedIntegrationFieldVariableType == "select" ? this.integrationVariablesObj[responseData.data[`${linkedColumn}`]] : responseData.data[`${linkedColumn}`]
        // this.$set(
        //   this.form,
        //   this.data.key,
        //   value
        // );
        if (Array.isArray(responseData.data)) {
          responseData.data.forEach((item) => {
    const linkedColumn =
      this.data.integration_settings["selectedIntegrationFieldVariable"];
    const fieldType =
      this.data?.integration_settings?.selectedIntegrationFieldVariableType;

    let value;
    switch (fieldType) {
      case "select":
        value = this.integrationVariablesObj[item[linkedColumn]];
        break;
      case "text":
        value = item[linkedColumn] || "";
        this.$set(this.form, this.data.key, value); 
        break;

      case "date":
        value = new Date(item[linkedColumn]).toISOString().slice(0, 10);
        break;

      case "number":
        value = parseFloat(item[linkedColumn]) || 0;
        break;

      case "boolean":
        value = Boolean(item[linkedColumn]);
        break;

      default:
        value = item[linkedColumn];
        break;
    }
    this.$set(this.form, `${this.data.key}`, value);
  });
    } else if (typeof responseData.data === "object") {
      let linkedColumn =
        this.data.integration_settings["selectedIntegrationFieldVariable"];
      let value =
        this.data?.integration_settings?.selectedIntegrationFieldVariableType ===
        "select"
          ? this.integrationVariablesObj[responseData.data[`${linkedColumn}`]]
          : responseData.data[`${linkedColumn}`];
      this.$set(this.form, this.data.key, value);
    }
      } catch (err) {
        return err;
      }
    },
    async getRequiredData() {
      try {
        let variableSelected =
          this.data.integration_settings.selectedIntegrationFieldVariable;
        let integrationVariableField = this.fieldsData.find(
          (variableField) =>
            variableField.key ==
            this.data.integration_settings.selectedIntegrationField
        );
        let connectionField = this.fieldsData.find(
          (field) => field.integration_settings.selectedIntegration
        );
        let applicationSelected =
          integrationVariableField.integration_settings.selectedApplication;
        let application = this.predefinedIntegrations?.find(
          (selectedApp) => selectedApp._id === applicationSelected
        );
        let app = application.key;
        let eventsArray = application?.events;
        let eventSelected =
          this.data.integration_settings.selectedVariableEvent;
        let event = eventsArray.find(
          (selectedEvent) => String(selectedEvent._id) === eventSelected
        );
        if (event && event.settings && event.settings.availableColumns) {
          let allColumns = event.settings.availableColumns;
          let columnFound = allColumns.find(
            (selectedColumn) => String(selectedColumn.key) === variableSelected
          );
          if (columnFound && columnFound.isApiData) {
            let requiredData = {};
            let detailKey = columnFound.requiredAsset;
            let requestBody = {
              selectedApplication: app,
              selectedEvent: detailKey,
              selectedIntegration:
                connectionField.integration_settings.selectedIntegration,
              requiredFields: requiredData,
            };
            let response = await this.fetchRequiredFieldData(requestBody);
            variableSelected =
              this.data.integration_settings.selectedIntegrationFieldVariable;
            if (response && Array.isArray(response)) {
              this.integrationVariablesObj = {};
              this.integrationVariables = response.map((variable) => {
                this.integrationVariablesObj[variable.id] = variable.name;
                return {
                  label: variable.name,
                  value: variable.id,
                };
              });
            }
          }
        }
      } catch (err) {
        return err;
      }
    },
    async fetchRequiredFieldData(payload) {
      try {
        const provider = payload.selectedApplication;
        const requestBody = {
          provider,
          field: payload.selectedEvent,
          formData: payload.requiredFields,
        };
        const response = await postAPICall(
          "POST",
          `integrations/service/get-requiredFields-data/${payload.selectedIntegration}`,
          requestBody
        );
        const data = response?.data || {};
        return data;
      } catch (error) {
        return error
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.el-select {
  width: 100% !important;

  .el-input,
  .el-input__inner {
    height: inherit !important;
  }
}
</style>
